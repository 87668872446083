<template>
  <div class="export-center-list">
    <app-header v-if="title" :title="title"></app-header>
    <div v-if="list.length > 0">
      <div class="content" v-for="(item, index) in list" :key="index">
        <div class="content-left">
          <div class="content-item">导出请求时间：{{ item.createTimeStr }}</div>
          <div class="content-item">报表类型：{{ item.taskName || "--" }}</div>
          <div class="content-item">状态： {{ item.taskStatusStr }}</div>
          <div class="content-item">
            请求人：{{ item.operatorName || "--" }}
          </div>
          <div class="content-item">
            文件名称：
            <span v-if="item.taskStatus == 1 && item.fileName" class="file-name"  @click="downloadFile(item)">{{item.fileName}}</span>
            <span v-else class="file-name-fail">--</span>
          </div>
        </div>
        <div class="content-right">
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="deleteItem(item)"
          ></i>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="limit"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div
      v-else
      style="
        text-align: center;
        color: #909399;
        font-size: 14px;
        padding-top: 200px;
      "
    >
      暂无数据
    </div>
  </div>
</template>
<script>
import Vue from "vue";
const TASKSTATUS = {
  0: "任务执行中",
  1: "已完成",
  2: "任务失败",
};

export default {
  data() {
    return {
      title: "导出中心",
      currentPage: 1,
      limit: 10,
      total: 0,
      list: [],
    };
  },
  activated() {
    this.getList();
  },
  mounted() {},
  methods: {
    getList() {
      let dto = {
        operatorId: this.cache.getLS("userInfo").userId,
        pageSize: this.limit,
        current: this.currentPage,
      };
      this.post("/task-service/task-executor-record/page", dto, {
        isUseResponse: true,
      }).then((res) => {
        if (res.data && res.data.code == 0) {
          this.list = res.data.data.records || [];
          
          if(Vue.config.BASE_URL == 'https://apartment-preapi.wfyundata.com' || Vue.config.BASE_URL == 'https://apartment-api.wavewisdom.com') {
            this.list.forEach((item) => {
              item.createTimeStr = item.createTime ? this.format(item.createTime) : "--";
              item.taskStatusStr = TASKSTATUS[item.taskStatus];
              // 预发布和生产 需要将地址转成https协议 否则无法下载
              if(item.filePath) {
                item.filePath = item.filePath.replace(/http/g, "https");
              }
            });
          }else {
            this.list.forEach((item) => {
              item.createTimeStr = item.createTime ? this.format(item.createTime) : "--";
              item.taskStatusStr = TASKSTATUS[item.taskStatus];
            });
          }

          this.total = res.data.data.total;
        } else {
          this.list = [];
          this.total = 0;
        }
      });
    },
    deleteItem(item) {
      this.get(`/task-service/task-executor-record/delete/${item.id}`).then(res => {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
        this.getList();
      });
    },
    handleSizeChange(val) {
      this.limit = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    downloadFile(item) {
      let url = item.filePath;
      if(!url) {
        return;
      }
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  border: 1px solid #eee;
  padding: 10px 0px 10px 15px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333333;
}
.content-right {
  width: 30px;
}
.content-item {
  padding-bottom: 6px;
}
.file-name {
  color: #30bac1;
  cursor: pointer;
}
.file-name-fail {
  color: #333333;
}
.pagination {
  padding-top: 6px;
  padding-bottom: 20px;
}
</style>