var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "export-center-list" },
    [
      _vm.title ? _c("app-header", { attrs: { title: _vm.title } }) : _vm._e(),
      _vm.list.length > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.list, function(item, index) {
                return _c("div", { key: index, staticClass: "content" }, [
                  _c("div", { staticClass: "content-left" }, [
                    _c("div", { staticClass: "content-item" }, [
                      _vm._v("导出请求时间：" + _vm._s(item.createTimeStr))
                    ]),
                    _c("div", { staticClass: "content-item" }, [
                      _vm._v("报表类型：" + _vm._s(item.taskName || "--"))
                    ]),
                    _c("div", { staticClass: "content-item" }, [
                      _vm._v("状态： " + _vm._s(item.taskStatusStr))
                    ]),
                    _c("div", { staticClass: "content-item" }, [
                      _vm._v(
                        "\n          请求人：" +
                          _vm._s(item.operatorName || "--") +
                          "\n        "
                      )
                    ]),
                    _c("div", { staticClass: "content-item" }, [
                      _vm._v("\n          文件名称：\n          "),
                      item.taskStatus == 1 && item.fileName
                        ? _c(
                            "span",
                            {
                              staticClass: "file-name",
                              on: {
                                click: function($event) {
                                  _vm.downloadFile(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.fileName))]
                          )
                        : _c("span", { staticClass: "file-name-fail" }, [
                            _vm._v("--")
                          ])
                    ])
                  ]),
                  _c("div", { staticClass: "content-right" }, [
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          _vm.deleteItem(item)
                        }
                      }
                    })
                  ])
                ])
              }),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 20, 30, 50],
                      "page-size": _vm.limit,
                      total: _vm.total
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        : _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                color: "#909399",
                "font-size": "14px",
                "padding-top": "200px"
              }
            },
            [_vm._v("\n    暂无数据\n  ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }